import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Musica from "../containers/musica";

type MusicaPageProps = {};

const MusicaPage: React.FunctionComponent<MusicaPageProps> = (props) => {
  return (
    <Layout>
      <SEO title="Música" description="Playlists, mixes." />

      <Musica />
    </Layout>
  );
};

export default MusicaPage;
