import * as React from "react";
import { MusicaWrapper, MusicaTitle, MusicaSubTitle } from "./style";
import Posts from "./posts";
import Banner from "./banner";
import Fade from "react-reveal/Fade";
import Newsletter from "../../components/newsletter/newsletter";

type MusicaProps = {};

const Musica: React.FunctionComponent<MusicaProps> = (props) => {
  return (
    <MusicaWrapper {...props}>
      <Banner />
      <Fade right>
        <MusicaTitle>lo último</MusicaTitle>
        <MusicaSubTitle>
          mixes, playlists, la música que necesitas 💪🏻
        </MusicaSubTitle>
      </Fade>
      <Posts />
      <Newsletter />
    </MusicaWrapper>
  );
};

export default Musica;
